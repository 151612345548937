import React, { useState } from "react"
import ReactPlayer from "react-player"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BigHeader from "../../components/bigHeader"
import { StaticQuery, graphql } from "gatsby"

import t from "locale"

const AnimalWelfare = ({ contentField }) => {
  const lang = "it"
  const [modal, setModal] = useState(false)
  const seo = contentField.seo
  const image = contentField.featuredImage

  return (
    <Layout
      lang={lang}
      translationEN="/animal-welfare/"
      translationDE="/de/tierwohl/"
      translationES="/es/bienestar-de-animales/"
      translationFR="/fr/bien-etre-animal/"
    >
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="IL BENESSERE DEGLI ANIMALI"
        image={require("../../assets/images/animal-welfare-background.jpg")}
      />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.AnimalWelfareACF.firstText,
          }}
        />
        <div className="text-container">
          <br />
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              setModal(true)
            }}
          >
            {t("See animation", lang)}
          </a>
          <br />
          <br />
        </div>
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.AnimalWelfareACF.secondText,
          }}
        />
        {modal && (
          <>
            <div className="subpage-modal">
              <div className="subpage-modal__wrapper">
                <button
                  className="subpage-modal__close"
                  onClick={e => {
                    setModal(false)
                  }}
                >
                  {t("Close", lang)}
                </button>
                <div className="embed-container">
                  <ReactPlayer
                    url="https://vimeo.com/877035930"
                    width="100%"
                    heigth="100%"
                    playing={modal === true ? true : false}
                    controls={true}
                    muted={false}
                  />
                </div>
              </div>
            </div>
            <div
              className="subpage-modal__overlay"
              onClick={e => {
                setModal(false)
              }}
            ></div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxNjA0" }) {
          AnimalWelfareACF {
            firstText
            secondText
          }
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <AnimalWelfare {...data} />}
  />
)
